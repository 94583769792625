import { Badge, Box, Button, Container, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Toolbar, Typography } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import MuiAppBar from '@mui/material/AppBar';
import NeotaskLogo from '../assets/neotask-white-no-slogan.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import axiosClient from '../libs/axios';
import useMobileScreen from '../hooks/useMobileScreen';
import { useCallback, useContext, useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AuthContext } from '../contexts/AuthContext';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ChangeProfileModal from './ChangeProfileModal';
import { enqueueSnackbar } from 'notistack';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Joyride, {Step} from 'react-joyride';

type ProfileProps = {
    id: number;
    name: string;
};

export default function AppBar() {

    const navigate = useNavigate();
    const telaMobile = useMobileScreen();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const location = useLocation();

    const { getUserData, logout, updateToken } = useContext(AuthContext);

    const [showChangeProfileModal, setShowChangeProfileModal] = useState<boolean>(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const steps: Step[] = [
        {
          target: '.menu-item-tour-0',
          content: 'Este é o menu onde você pode acessar suas tarefas!',
        },
        {
          target: '.menu-item-tour-1',
          content: 'Aqui você encontra os fluxos de trabalho.',
        },
        {
          target: '.menu-item-tour-2',
          content: 'Nesta seção, veja seus processos em andamento.',
        },
    ]

    const { data: countTasks } = useQuery({
        queryKey: 'tasks-count',
        queryFn: () => axiosClient.get("/task/count"),
        cacheTime: 0
    })

    const {data: onboardUser, refetch} = useQuery({
        queryKey: 'onboard-user',
        queryFn: () => axiosClient.get(`/user/onboard/${userData?.id}`),
        cacheTime: 0
    })


    let userData = getUserData();
    const [run, setRun] = useState(true)

    const queryClient = useQueryClient();

    const { mutate } = useMutation(
        (data: { group_id: number }) => {
            return axiosClient.post('/select-group', data, { headers: { 'Content-Type': 'multipart/form-data' }, })
        },
        {
            onSuccess: (response) => {
                updateToken(response.data);
                enqueueSnackbar('Perfil alterado com sucesso', { variant: 'success' });
                queryClient.invalidateQueries();
            },
            onError: () => {
                enqueueSnackbar('Erro ao trocar perfil', { variant: 'error' });
            },
        }
    );

    const onSubmit = (selectedProfile: ProfileProps) => {
        mutate({ group_id: selectedProfile.id });
    }

    const {mutate: onBoardMutate} = useMutation(
        (data: { dashboard: boolean}) => {
            return axiosClient.put('/user/onboard', data, { headers: { 'Content-Type': 'application/json' } });
        }, {
            onSuccess: () => {
                refetch()
                setRun(false)
            }
        }
    )

    const menu = [
        { title: "Tarefas", path: "tasks", icon: <ViewKanbanIcon /> },
        { title: "Fluxos", path: "flows", icon: <AccountTreeIcon /> },
        { title: "Processos", path: "processes", icon: <AutorenewIcon /> }
    ];

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    
    const handleTourEnd = (data: any) => {
       
        if ((data?.action === 'skip' && onboardUser?.data?.onboard?.dashboard) || 
            (data?.action === 'next' && data?.index === 2 && data?.lifecycle === 'complete' && onboardUser?.data?.onboard?.dashboard)) {
            onBoardMutate({ dashboard: false })
        }
    }

    return (
        <>
            {onboardUser?.data?.onboard?.dashboard && (
                <Joyride steps={steps} run={run} continuous  scrollToFirstStep  showSkipButton  showProgress  disableScrolling locale={{
                    back: "Voltar",
                    close: "Fechar",
                    last: "Finalizar",
                    next: "Próximo",
                    skip: "Pular",
                    nextLabelWithProgress: "Próximo"
                }} 
                styles={{
                    options: {
                        arrowColor: '#fff',
                        backgroundColor: '#fff',
                        textColor: 'black',
                        zIndex: 1000,
                    },
                }} callback={handleTourEnd}/>
                )
            }

            <MuiAppBar position="fixed">
                <Toolbar>
                    <Container sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Box display="flex" gap={1} alignItems="center">
                            {telaMobile && (
                                <IconButton color="inherit" onClick={toggleMobileMenu} edge="start" sx={{ padding: 0, marginLeft: -1 }}>
                                    <MenuIcon />
                                </IconButton>
                            )}
                            
                            <Box style={{ cursor: 'pointer' }}>
                                <img style={{ 
                                    width: telaMobile ? 100 : 150, 
                                    marginRight: telaMobile ? 0 : 24,
                                    marginTop: 10 
                                }} src={NeotaskLogo} alt='' />
                            </Box>
                            
                            {!telaMobile && (
                                <Stack direction="row" sx={{ height: 71 }} spacing={4}>
                                    {menu.map((i: any, index) => (
                                        <Button onClick={() => navigate('/' + i.path)} variant="text" startIcon={i.icon}
                                            className={`menu-item-tour-${index}`}
                                                sx={{ 
                                                    color: "#FFF", 
                                                    fontWeight: (location.pathname === ('/' + i.path)) ? 'bold' : 0, 
                                                    borderBottom: (location.pathname === ('/' + i.path)) ? '3px solid #FFF' : 0 
                                                }}
                                            >
                                            {i.title}
                                        </Button>
                                    ))}
                                </Stack>
                            )}
                        </Box>

                        <Box display="flex" justifyContent="flex-end" alignItems="center" width={telaMobile ? "auto" : "10%"}>
                            <IconButton color="inherit" onClick={() => navigate('/tasks')}>
                                <Badge badgeContent={countTasks?.data} color="error">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>

                            <IconButton id="basic-menu" color="inherit" style={{ marginLeft: 12 }}
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    setAnchorEl(event.currentTarget);
                                }}>
                                <PersonIcon />
                            </IconButton>
                            <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}>
                                <MenuItem>
                                    <ListItemIcon>
                                        <AccountCircleIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography lineHeight={0.5}>{userData?.name}</Typography>
                                        {userData?.is_admin ? (
                                            <Typography variant="caption">Administrador</Typography>
                                        ) : userData?.group && (
                                            <Typography variant="caption">{userData.group.name}</Typography>
                                        )}
                                    </ListItemText>
                                </MenuItem>

                                <Divider />

                                { userData?.is_admin &&
                                    <MenuItem onClick={() => { navigate("/settings"); setAnchorEl(null); }}>
                                        <ListItemIcon>
                                            <SettingsIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Configurações</ListItemText>
                                    </MenuItem>
                                }

                                { userData?.groups && userData.groups.length > 1 && !userData?.is_admin && (
                                    <MenuItem onClick={() => { setShowChangeProfileModal(true); }}>
                                        <ListItemIcon>
                                            <PeopleAltIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText>Trocar perfil</ListItemText>
                                    </MenuItem>
                                ) }

                                <ChangeProfileModal profiles={userData?.groups as ProfileProps[]} onSubmit={onSubmit} open={showChangeProfileModal}onClose={() => setShowChangeProfileModal(false)}/>
                                <MenuItem onClick={() => logout()}>
                                    <ListItemIcon>
                                        <LogoutIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Sair</ListItemText>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Container>
                </Toolbar>
            </MuiAppBar>

            <Drawer anchor="left" open={mobileMenuOpen} onClose={toggleMobileMenu}>
                <Box sx={{ width: 250, pt: 2 }}>
                    {onboardUser?.data?.onboard?.dashboard &&(
                        <Joyride steps={steps} run={run} continuous  scrollToFirstStep  showSkipButton disableScrolling showProgress  locale={{
                            back: "Voltar",
                            close: "Fechar",
                            last: "Finalizar",
                            next: "Próximo",
                            skip: "Pular",
                            nextLabelWithProgress: "Próximo"
                        }} 
                        styles={{
                            options: {
                                arrowColor: '#fff',
                                backgroundColor: '#fff',
                                textColor: 'black',
                                zIndex: 3000,
                            },
                        }} callback={handleTourEnd}/>
                    )}

                    {menu.map((item, index) => (
                        <MenuItem key={item.path} onClick={() => {
                                navigate('/' + item.path);
                                toggleMobileMenu();
                            }}
                            sx={{
                                backgroundColor: location.pathname === ('/' + item.path) ? 'rgba(0, 0, 0, 0.04)' : 'transparent'
                            }}
                            className={`menu-item-tour-${index}`}>
                            <ListItemIcon >{item.icon}</ListItemIcon>
                            <ListItemText primary={item.title} />
                        </MenuItem>
                    ))}
                </Box>
            </Drawer>
            <Toolbar />
        </>
    )
}