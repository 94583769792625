import { useState } from "react";
import { Box, Dialog, DialogContent, DialogTitle, DialogActions, IconButton, CircularProgress, Button, Chip, Typography } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useQuery } from "react-query";
import axiosClient from "../../libs/axios";
import { BpmnViewer } from "./BpmnViewer";

type ViewFlowProps = {
    flowId: number;
    version?: number
    nameFlow?: string
}

export function ViewFlow({ flowId, version, nameFlow }: ViewFlowProps) {
    const [open, setOpen] = useState(false);
    const [key, setKey] = useState(0);

    const { data, isLoading } = useQuery({
        queryKey: ["flow", flowId, version, open],
        queryFn: () => axiosClient.get(`/flow/${flowId}` , {
            params: {version}
        }),
        enabled: open,
    });

    const handleOpen = () => {
        setKey(prev => prev + 1); // Força recriação do componente
        setOpen(true);
    };
    
    const handleClose = () => setOpen(false);

    return (
        <>
            <IconButton
                onClick={handleOpen}
                size="small"
            >
                <VisibilityIcon />
            </IconButton>

            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>Visualização do Fluxo: "{nameFlow} - Versão: {version}"
                </DialogTitle>
                <DialogContent>
                
                    {isLoading ? (
                        <Box display="flex" justifyContent="center" p={3}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box p={3}>
                            {data?.data?.spec && (
                                <BpmnViewer 
                                    key={key} 
                                    spec={data.data.spec} 
                                />
                            )}
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Fechar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
} 