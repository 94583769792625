import React from 'react';
import { Box, Button, CircularProgress, FormControl, FormControlLabel, IconButton, List, ListItem, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';
import { Controller, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { enqueueSnackbar } from 'notistack';
import axiosClient from '../../../../libs/axios';
import { DynamicFormRender } from '../../../../components/DynamicForm/DynamicFormRender';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

interface ResolveTaskFormProps {
    code: string;
    task: any;
    onClose: () => void;
    version?: number

}

interface FormResponse {
    [key: string]: FormResponse | string | Array<String> | File;
}

interface FormValues {
    observation?: string | null;
    form_response?: FormResponse | null;
    chosen?: string | null;
    files: File[];
    files_draft: any[];
}

export function ResolveTaskForm({ code, task, onClose }: ResolveTaskFormProps) {

    const methods = useForm<FormValues>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {
            observation: task.observation,
            chosen: task.chosen,
            files: [],
            files_draft: task.files
        },
    });

    const queryClient = useQueryClient();

    const { mutate: resolveTask, isLoading: isResolvingTask } = useMutation(
        (data: FormData) => axiosClient.post('/task/resolve/' + task.id, data, { 
            headers: { 'Content-Type': 'multipart/form-data' }
        }),
        {
            onSuccess: (response) => {
                enqueueSnackbar('Tarefa resolvida com sucesso', { variant: 'success' });
                queryClient.invalidateQueries(["process", code]);
                methods.setValue("observation", "");
                methods.setValue("files", []);
            },
            onError: () => {
                enqueueSnackbar('Erro ao resolver processo', { variant: 'error' });
            },
        }
    );

    const { mutate: saveDraft, isLoading: isSavingDraft } = useMutation(
        (data: FormData) => axiosClient.post('/task/draft/' + task.id, data, {
            headers: { 'Content-Type': 'multipart/form-data' }
        }),
        {
            onSuccess: () => {
                enqueueSnackbar('Rascunho salvo com sucesso', { variant: 'success' });
            },
            onError: () => {
                enqueueSnackbar('Erro ao salvar rascunho', { variant: 'error' });
            },
        }
    );

    const processFormResponse = (obj: any, parentKey = ''): any => {
        if (obj instanceof File) {
            return `__FILE__${parentKey}`;
        } else if (Array.isArray(obj)) {
            return obj.map((item, index) => processFormResponse(item, `${parentKey}[${index}]`));
        } else if (obj && typeof obj === 'object') {
            if (obj.$d && typeof obj.toISOString === 'function') {
                return obj.toISOString();
            }
            const processedObj: any = {};
            for (const key in obj) {
                processedObj[key] = processFormResponse(obj[key], `${parentKey}[${key}]`);
            }
            return processedObj;
        }
        return obj;
    };

    const prepareFormData = (data: FormValues): FormData => {
        const formData = new FormData();

        if (data.observation) {
            formData.append('observation', data.observation);
        }

        if (data.chosen) {
            formData.append('chosen', data.chosen);
        }

        if (data.form_response) {
            const processedResponse = processFormResponse(data.form_response);
            formData.append('form_response', JSON.stringify(processedResponse));
        }

        data.files.forEach((file: File, index: number) => {
            formData.append(`files[${index}]`, file);
        });

        return formData;
    };

    const onSubmit: SubmitHandler<FormValues> = (data) => {
        const formData = prepareFormData(data);
        resolveTask(formData);
    };

    const onSaveDraft = () => {
        const data = methods.getValues();
        const formData = prepareFormData(data);
        saveDraft(formData);
    };

    const handleFileRemove = (index: number): void => {
        const updatedFiles = methods.getValues('files').filter((file: File, i: number) => i !== index);
        methods.setValue("files", updatedFiles);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            methods.setValue("files", Array.from(event.target.files));
        }
    };

    const handleFileRemoveDraft = async (index: number): Promise<void> => {
        const file = methods.getValues('files_draft')[index];
        if (file.id) {
            try {
                await axiosClient.delete(`/process/${code}/file/${file.id}`);
                queryClient.invalidateQueries(["process", code]);
            } catch (error) {
                enqueueSnackbar('Erro ao remover arquivo', { variant: 'error' });
                return;
            }
        }
        const updatedFiles = methods.getValues('files_draft').filter((file: any, i: number) => i !== index);
        methods.setValue("files_draft", updatedFiles);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <FormProvider {...methods}>
            {
                task.type == 'Conditional' && (
                    <Box mb={2}>
                        <Typography variant="caption" fontWeight="bold">
                            Escolha uma opção *
                        </Typography>
                        <Controller
                            name="chosen"
                            control={methods.control}
                            rules={{ required: 'Campo obrigatório' }}
                            render={({ field }) => (
                                <RadioGroup
                                    {...field}
                                    row
                                    name="row-radio-buttons-group"
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        field.onChange((event.target as HTMLInputElement).value);
                                    }}
                                >
                                    {task.options.map((i: string) => <FormControlLabel value={i} control={<Radio />} label={i} />)}
                                </RadioGroup>
                            )}
                        />
                    </Box>
                )
            }

            {
                task.form &&
                <Controller
                    name="form_response"
                    control={methods.control}
                    rules={{ validate: (value) => { 
                        if(task.form.find((i: any) => i.isRequired) == undefined) return true;
                        return task.form.find((i: any) => i.isRequired && !value?.hasOwnProperty(i.id)) == undefined;
                    }}}
                    render={({ field }) => (
                        <DynamicFormRender
                            components={task.form}
                            defaultValues={task.form_response}
                            onChange={(values: any) => {
                                field.onChange(values);
                            }}
                        />
                    )}
                />
            }

            <FormControl fullWidth variant="outlined" required>
                <Controller
                    name="observation"
                    control={methods.control}
                    rules={{ required: task.required_observation ? 'Campo obrigatório' : false }}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            label="Observações"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={4}
                            required={task.required_observation}
                        />
                    )}
                />
            </FormControl>

            <List>
                {methods.watch('files_draft').map((file: any, index: number) => (
                    <ListItem key={index} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography 
                            variant="body2" 
                            sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
                            onClick={() => window.open(file.path, '_blank')}
                        >
                            {file.name}
                        </Typography>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleFileRemoveDraft(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}

                {methods.watch('files').map((file: File, index: number) => (
                    <ListItem key={index} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2">{file.name}</Typography>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleFileRemove(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button 
                    variant="contained" 
                    disabled={isResolvingTask || !methods.formState.isValid}
                    onClick={methods.handleSubmit(onSubmit)}
                >
                    {isResolvingTask && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                    Resolver tarefa
                </Button>

                {!isMobile ? (
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Button
                            variant="text"
                            disabled={isSavingDraft}
                            onClick={onSaveDraft}
                        >
                            {isSavingDraft && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                            Salvar rascunho
                        </Button>
                        <Button
                            variant="outlined"
                            component="label"
                            startIcon={<FileUploadOutlinedIcon />}
                        >
                            Anexar arquivos
                            <input
                                type="file"
                                hidden
                                multiple
                                onChange={handleFileChange}
                            />
                        </Button>
                    </Box>
                ) : (
                    <>
                        <IconButton onClick={handleMenuOpen}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem 
                                onClick={() => {
                                    onSaveDraft();
                                    handleMenuClose();
                                }}
                                disabled={isSavingDraft}
                            >
                                {isSavingDraft && <CircularProgress size={14} style={{ marginRight: 12 }} />}
                                Salvar rascunho
                            </MenuItem>
                            <MenuItem
                                component="label"
                                onClick={handleMenuClose}
                            >
                                Anexar arquivos
                                <input
                                    type="file"
                                    hidden
                                    multiple
                                    onChange={handleFileChange}
                                />
                            </MenuItem>
                        </Menu>
                    </>
                )}
            </Box>

        </FormProvider>
    );
}
