import { Box, Divider, Link, Typography } from "@mui/material";
import { DynamicFormComponentProps } from ".";
import { useState, useRef, useEffect } from "react";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { formatDate } from "../../utils/formatDate";

type DynamicFormRenderResponseProps = {
    form: Array<DynamicFormComponentProps>;
    formResponse: any;
    alwaysExpanded?: boolean;
    files?: any[];
};

type FormResponseValueProps = {
    label: string;
    value: any;
    type?: string;
    components?: Array<DynamicFormComponentProps>;
    files?: any[];
};

// Função auxiliar para formatar as chaves
const formatKey = (key: string) => {
    return key
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export function DynamicFormRenderResponse({ form, formResponse, alwaysExpanded = false, files = [] }: DynamicFormRenderResponseProps) {
    const [expanded, setExpanded] = useState(alwaysExpanded);
    // Adicionar ref para medir altura do conteúdo
    const contentRef = useRef<HTMLDivElement>(null);
    // Estado para controlar se deve mostrar o botão
    const [showButton, setShowButton] = useState(false);

    console.log('files', files);

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setExpanded(!expanded);
    };

    // Verificar altura do conteúdo após renderização
    useEffect(() => {
        if (contentRef.current) {
            setShowButton(!alwaysExpanded && contentRef.current.scrollHeight > 100);
        }
    }, [form, formResponse, alwaysExpanded]);

    return (
        <Box position="relative">
            <Box 
                ref={contentRef}
                sx={{ 
                    maxHeight: expanded || alwaysExpanded ? 'auto' : '100px',
                    overflow: 'hidden',
                    position: 'relative',
                    '&::after': (!expanded && showButton) ? {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: '40px',
                        background: 'linear-gradient(180deg, transparent, white)',
                        pointerEvents: 'none'
                    } : {}
                }}
            >
                {form.map((item, index) => (
                    <FormResponseValue 
                        key={item.id} 
                        label={item.name} 
                        value={formResponse[item.id]}
                        type={item.type} 
                        components={item.type === 'group' ? item.components : []} 
                        files={files} 
                    />
                ))}
            </Box>
            {showButton && !alwaysExpanded && (
                <Box 
                    onClick={handleClick}
                    sx={{ 
                        textAlign: 'center',
                        position: 'relative',
                        zIndex: 1
                    }}
                >
                    <Typography 
                        component="a"
                        variant="caption"
                        sx={{ 
                            color: 'primary.main',
                            cursor: 'pointer',
                            textDecoration: 'none',
                            '&:hover': { textDecoration: 'underline' }
                        }}
                    >
                        {expanded ? 'Mostrar menos' : 'Mostrar mais'}
                    </Typography>
                </Box>
            )}
        </Box>
    )
}

function FormResponseValue({ label, value, type, components, files = [] }: FormResponseValueProps) {
    if (!value) return (
        <Typography variant="caption" component='div'>
            <b>{formatKey(label)}:</b>
        </Typography>
    );

    // Se tiver componentes, renderizar o grupo
    if (components && components.length > 0) {
        return (
            <Typography variant="caption" component='div' my={1} sx={{ border: '1px solid #ccc', p: 1, borderRadius: 1 }}>
                <Typography variant="body2" component='div' sx={{ fontWeight: 'bold', mb: 1 }}>{formatKey(label)}</Typography>
                <Box ml={1}>
                    {components.map((item, index) => (
                        <FormResponseValue 
                            key={item.id} 
                            label={item.name} 
                            value={value[item.id]} 
                            components={item.type === 'group' ? item.components : []} 
                            files={files} 
                        />
                    ))}
                </Box>
            </Typography>
        );
    }

    if (type === 'address') {
        return (
            <Typography variant="caption" component="div">
                <b>{formatKey(label)}:</b>{' '}
                {value.logradouro}, {value.numero} {value.complemento ? `- ${value.complemento}` : ''} - {value.bairro} - {value.cidade}/{value.uf} - CEP: {value.cep}
            </Typography>
        );
    }

    if(typeof value === 'object' && !Array.isArray(value)) {
        return (
            <Typography variant="caption" component='div' my={1}>
                <b>{formatKey(label)}:</b>
                <Box ml={1}>
                    {Object.entries(value).map(([key, val]) => (
                        <FormResponseValue 
                            key={key} 
                            label={key} 
                            value={val} 
                            components={[]} 
                            files={files} 
                        />
                    ))}
                </Box>
            </Typography>
        );
    }

    if (type === 'date') {
        return (
            <Typography variant="caption" component="div">
                <b>{formatKey(label)}:</b>{' '}
                {formatDate(value).slice(0, 10)}
            </Typography>
        );
    }

    // Array de objetos
    if (Array.isArray(value) && typeof value[0] === 'object') {
        return (
            <>
                <Typography variant="caption" component='div' my={1}>
                    <b>{formatKey(label)}:</b>
                </Typography>
                <Box ml={2}>
                    {value.map((obj, index) => (
                        <Box key={index}>
                            {Object.entries(obj).map(([key, val]) => (
                                <Typography key={key} variant="caption" component='div'>
                                    <b>{formatKey(key)}</b>: {val?.toString() || '-'}
                                </Typography>
                            ))}
                            {index < value.length - 1 && <Divider sx={{ my: 1 }} />}
                        </Box>
                    ))}
                </Box>
            </>
        );
    }

    // Array de strings
    if (Array.isArray(value)) {
        return (
            <Typography variant="caption" component='div'>
                <b>{formatKey(label)}:</b>
                <Box component="ul" sx={{ m: 0, pl: 2 }}>
                    {value.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </Box>
            </Typography>
        );
    }

    if(typeof value === 'string' && value.includes('__FILE__')) {
        const file = files.find((item: any) => item.form_response_ref === value.match(/\[(.*?)\]/)?.[1]);
        return (
            <Typography variant="caption" component='div' sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <b>{formatKey(label)}:</b> 
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <AttachFileIcon sx={{ fontSize: 14 }} />
                    <Link target="_blank" href={file?.path}>{file?.name}</Link>
                </Box>
            </Typography>
        );
    }

    // String simples
    return (
        <Typography variant="caption" component='div'>
            <b>{formatKey(label)}:</b>{' '}
            {value.toString().split('\n').map((line: string, index: number, array: string[]) => (
                <span key={index}>
                    {line}
                    {index < array.length - 1 && <br />}
                </span>
            ))}
        </Typography>
    );
} 